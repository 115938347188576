@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  cursor: pointer;
  @include body-text(md, $boldFontWeight);
  color: $deepPurple;
}

.content {
  width: 292px;
  margin: auto;
  @include body-text(sm);
  margin-top: 8px;
}

.content ul {
  list-style-position: inside;
  text-indent: -1.4em;
}

.content li {
  color: $black80;
  text-align: left;
  margin-bottom: 12px !important;
}
