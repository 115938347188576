@import 'src/styles/variables';

.label {
  color: $black100;
  text-align: center;
  margin-bottom: 32px;
}

.button {
  margin-bottom: 20px;
}
