@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radio {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid $black10;
  border-radius: 50%;
}

.radio.selected {
  width: 8px;
  height: 8px;
  border: 6px solid $deepPurple;
}

.label {
  color: $black80;
}
