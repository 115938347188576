@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  margin-bottom: 32px;
}

.title {
  @include body-text(lg, $boldFontWeight);
  text-align: center;
  margin-bottom: 16px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
}

.cardPreview {
  margin-bottom: 12px;
  width: 230px;
  height: 145px;
}

.editMessage {
  display: flex;
  align-items: center;
  width: 230px;
}

.shareMessage {
  @include body-text(sm, $semiBoldFontWeight);
  font-family: $poppinsFontStyle;
  flex-grow: 1;
  background: transparent;
  border: 0;
  resize: none;
  outline: none;
  border-radius: 12px;
  overflow: hidden;
}

.editButton {
  cursor: pointer;
  margin-left: 8px;
  min-width: 32px;
  height: 32px;
}

.shareMessage.isEditing {
  @include body-text(sm, $semiBoldFontWeight);
  padding: 16px 20px;
  background-color: $black6;
}
