@import 'src/styles/variables';
@import 'src/styles/mixins';

.shareButton {
  background-color: $white;
  border-radius: 12px;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.shareButton.active {
  border-width: 2px;
  padding: 11px;
  border-color: $deepPurple;
}

.radio {
  width: 16px;
  height: 16px;
  border: 2px solid $black10;
  border-radius: 50%;
}

.active .radio {
  border: 6px solid $deepPurple;
  width: 8px;
  height: 8px;
}

.icon {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $white;
  margin: 0 12px;
}

.name {
  @include body-text(md, $semiBoldFontWeight);
}
