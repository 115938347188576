@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
}

.icon {
  text-align: center;
  margin-bottom: 32px;
}

.title {
  @include h1;
  text-align: center;

  @include sm {
    margin-bottom: 16px;
  }
}

.subtitle {
  @include subtitle;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;

  @include sm {
    margin-top: 16px;
  }
}
