@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
  border-radius: 12px;
  border: 1px solid $black10;
  padding: 16px;
  box-shadow: $deepBoxShadow;
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 282px;
  margin: 0 auto 32px;
}

.cardImageContainer {
  --p: calc(var(--percentage) * 1%);
  background: conic-gradient($green var(--p), #b39cfc var(--p));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.cardImage {
  position: relative;
  top: 12%;
  left: -4%;
  width: 47px;
}

.checkContainer {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;

  svg {
    width: 14px;
    height: 14px;
  }
}

.cardMeta {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .cardTitle {
    @include body-text(md, $semiBoldFontWeight);
  }

  .cardSubtitle {
    @include body-text(sm);
    text-align: left;
  }

  .cardHighlighted {
    @include body-text(xs, $boldFontWeight);
  }
}

.dropdownIcon {
  width: 16px;
  height: 16px;

  path {
    stroke: $deepPurple;
  }
}
